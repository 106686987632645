@charset "UTF-8";

@page {
//  size: A4;
//  margin: 10mm;
}
@media print {
  body {
    font-size:12pt;
    margin: 0 auto;
    width: 100%;
    border: solid 1px $color-light-border;
    padding:5mm;
  }

  header {
    border-bottom: solid 1px $color-dark-border;
    display: none;
    position: relative;
  }

  footer {
    border-top: solid 1px $color-dark-border;
    display: none;
    position: relative;
  }


  .container {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }

}
