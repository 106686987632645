@charset "UTF-8";


@keyframes fade-in2 {
  0% {
    display: none;
    opacity: 0;
    bottom:-100%;
  }

  50% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
    bottom:0;
  }
}

//
// base
//
.l-contact {
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ccc;
  box-shadow : 0px -1px 2px rgba(0, 0, 0, 0.75);
  .title-contact {
    background-color: #c1272d;
    font-size:1.077rem;
    color:$color-white;
    text-align: center;
    line-height: 1;
    margin: 0;
    padding:12px 0;
    font-weight: bold;
  }
  &.visible {
    animation-duration: 1s;
    animation-name: fade-in2;
    display: block;
    opacity: 1;
  }
  @media (min-width: $screen-md-min) {
    display: none;
    &.visible {
    display: none;
      
    }
  }
}
.tel-home,
.tel-work {
  color:$color-white;
  line-height: 1;
  font-size:.92rem;
  font-weight: bold;
  text-decoration: none;
  width:50%;
  float: left;
  text-align: center;
  padding:8px;
  &:after {
    display: block;
    content: '';
    background-image: url(../img/common/icon_tel.svg);
    width:18px;
    height: 18px;
    margin: 8px auto 0 auto;
  }
}
.tel-home {
  background-color: #1897f2;
}
.tel-work {
  background-color: #39b54a;
}

// contact 
.sec-contact {
  background-color: #e7f1f8;
  padding:16px 0;
  display: none;
  @media (min-width: $screen-md-min) {
    display: block;
  }
}
.card-contact_home,
.card-contact_work {
  background-color: $color-white;
  border: solid 2px $color-home;
  padding: 12px 28px;
  .card-title {
    font-size: 1.15rem;
    line-height: 1;
    margin: 0;
    border-bottom: solid 2px $color-home;
    text-align: center;
    padding-bottom: 7px;
    margin-bottom: 7px;
  }
  .card-busyo {
    font-size: 1.077; 
    line-height: 1;
    margin: 7px 0;
    text-align: center;
  }
  @media (min-width: $screen-md-min) {
    border: solid 5px $color-home;
    padding: 18px 28px;
    .card-title {
      font-size: 1.6rem;
    }
  }
}
.card-contact_work {
  border: solid 2px $color-work;
  .card-title {
    border-bottom: solid 2px $color-work;
  }
  @media (min-width: $screen-md-min) {
    border: solid 5px $color-work;
    .card-title {
      border-bottom: solid 2px $color-work;
    }
    
  }
}

.shop-info {
  margin-bottom: 10px;
  .shop-name {
    background-color: $color-home;
    color:$color-white;
    text-align: center;
    width:160px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 12px;
    display: block;
    font-weight: bold;
    line-height: 1;
    padding:5px 0;
    font-size: 1.077rem;
    vertical-align: middle;
  }
  .shop-tel {
    margin-top: 6px;
    vertical-align: bottom;
    display: block;
    font-size: 1.77rem;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    a {
      display: block;
      text-decoration: none;
      background-color: $color-company;
      color:$color-white;
      border-radius: 6px;
      padding: 8px;
      text-align: center;
      &:before {
        display: inline-block;
        content: " ";
        width: 18px;
        height: 18px;
        margin-right: .5em;
        background-image: url(../img/common/icon_tel.svg);
      }
    }
  }
  .shop-time {
    font-size: .8rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 5px;
    text-align: center;
  }
  @media (min-width: $screen-md-min) {
    margin-bottom: 20px;
    .shop-name {
      margin-right: 15px;
      display: inline-block;
      &.l2 {
        line-height: 1.2;
      }
    }
    .shop-tel {
      margin-top: 0;
      display: inline-block;
      a {
        color:$color-text;
        background-color: transparent;
        display: inline-block;
        border-radius: 6px;
        padding: 0;
        text-align: left;
        &:before {
          display: none;
        }
      }
    }
    .shop-time {
      font-size: 1.077rem;
      margin-top: 9px;
      text-align: left;
    }
  }
}

.busyo-info {
  margin-bottom: 15px;
  .busyo-name {
    font-size:0.9rem;
    font-weight: bold;
    vertical-align: middle;
    margin: 0;
    line-height: 1;
    text-align: center;
  }
  .busyo-tel {
    margin-top: 6px;
    vertical-align: bottom;
    display: block;
    font-size: 1.77rem;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    a {
      display: block;
      text-decoration: none;
      background-color: $color-company;
      color:$color-white;
      border-radius: 6px;
      padding: 8px;
      text-align: center;
      &:before {
        display: inline-block;
        content: " ";
        width: 18px;
        height: 18px;
        margin-right: .5em;
        background-image: url(../img/common/icon_tel.svg);
      }
    }
  }
  .busyo-time {
    font-size: .8rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 5px;
    text-align: center;
  }
  @media (min-width: $screen-md-min) {
    margin-bottom: 15px;
  .busyo-name {
    margin-right: 15px;
    display: inline-block;
    font-weight: bold;
    line-height: 1;
    padding:5px 0;
    font-size: 1.077rem;
    vertical-align: middle;
  }
  .busyo-tel {
    margin-top: 0;
    display: inline-block;
    a {
      color:$color-text;
      background-color: transparent;
      display: inline-block;
      border-radius: 6px;
      padding: 0;
      text-align: left;
      &:before {
        display: none;
      }
    }
  }
  .busyo-time {
    text-align: left;
    font-size: 1.077rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 9px;
  } 
  }
}

.other-contact {
  margin-top: 15px;
  .other-title {
    font-size:1.1rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
    line-height: 1;
  }
  .other-info {
    .other-name {
      font-size:0.9rem;
      font-weight: bold;
      vertical-align: middle;
      margin: 0;
      line-height: 1;
      text-align: center;
    }
    .other-tel {
      margin-top: 6px;
      vertical-align: bottom;
      display: block;
      font-size: 1.77rem;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1;
      a {
        display: block;
        text-decoration: none;
        background-color: $color-company;
        color:$color-white;
        border-radius: 6px;
        padding: 8px;
        text-align: center;
        &:before {
          display: inline-block;
          content: " ";
          width: 18px;
          height: 18px;
          margin-right: .5em;
          background-image: url(../img/common/icon_tel.svg);
        }
      }
    }
  }
  @media (min-width: $screen-md-min) {
    margin-top: 30px;
    .other-title {
      font-size:1.23rem;
      margin-bottom: 10px;
    }
    .other-info {
      .other-name {
        font-size:0.92rem;
        margin: 0 20px 0 0;
        display: inline-block;
      }
      .other-tel {
        margin-top: 0;
        display: inline-block;
        a {
          color:$color-text;
          background-color: transparent;
          display: inline-block;
          border-radius: 6px;
          padding: 0;
          text-align: left;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.operation-info {
  background-color:$color-home_light;
  padding:5px 10px;
  .operation-text {
    margin: 0;
    font-size: .9rem;
  }
  @media (min-width: $screen-md-min) {
    padding:5px 18px;
    .operation-text {
      margin: 0;
      font-size: 1.077rem;
    } 
  }
}
