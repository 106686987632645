@charset "UTF-8";

.page-head_company,
.page-head_work,
.page-head {
  color: $color-home;
  font-size: 1.15rem;
  margin: 8px 0 10px 0;
  line-height: 1;
  @media (min-width: $screen-md-min) {
    font-size: 2.15rem;
    margin: 20px 0 10px 0;
  }
}
.page-head_work {
  color: $color-work;
}
.page-head_company {
  color: $color-company;
}


.page-lead_company,
.page-lead_work,
.page-lead { 
  font-size: .77rem;
  @media (min-width: $screen-md-min) {
    font-size: 1.15rem;
    background-image: url(../img/common/header_home.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    min-height: 200px;
    padding-top: 20px;
    margin: 0 0 20px 0;
  }
}
.page-lead_work { 
  @media (min-width: $screen-md-min) {
    background-image: url(../img/common/header_work.jpg);
  }
}
.page-lead_company { 
  @media (min-width: $screen-md-min) {
    background-image: url(../img/common/header_company.jpg);
  }
}

.sec-home,
.sec-work,
.sec-company {
    padding-bottom: 50px;
  @media (min-width: $screen-md-min) {
    padding-bottom: 100px;
  }
}

//
// 家庭用事業
//
.card-home {
  .card-title {
    font-size: 1rem;
    background : linear-gradient(180deg, rgba(188, 223, 249, 1) 0%, rgba(232, 244, 252, 1) 99.96%);
    border-bottom: solid 2px $color-home;
    line-height: 1;
    padding:8px 8px;
    margin: 0 0 10px 0;
  }
  @media (min-width: $screen-md-min) {
    .card-title {
      font-size: 1.23rem;
      padding:10px 12px;
    } 
  }
}
.card-home-product {
  display: block;
  text-decoration: none;
  border: solid 1px $color-home;
  background-color: #fff;
  margin-bottom: $col-gutter;
  padding: 5px;
  &:hover {
    background-color: #fafaff;
  }
  .product-name{
    font-size: .92rem;
    background-color: $color-home;
    color:$color-white;
    text-align: center;
    margin-bottom: 5px;
  }
  .product-image {
    display: block;
    text-align: center;
    img {
      vertical-align: bottom;
    }
  }
  @media (min-width: $screen-md-min) {
    display: block;
    text-decoration: none;
    border: solid 1px $color-home;
    background-color: #fff;
    margin-bottom: $col-gutter;
    padding: 10px;
    .product-name{
      font-size: 1.15rem;
      margin-left: 15px;
      margin-right: 15px;
      background-color: $color-home;
      color:$color-white;
      text-align: center;
      margin-bottom: 10px;
    }
    .product-image {
      display: block;
      text-align: center;
    } 
  }
}

//
// 事業用
//
.card-work {
  @include clearfix();
  border: solid 1px $color-work;
  padding:10px;
  margin-bottom: 15px;
  .card-info {
    @include clearfix();
    margin-bottom: 10px;
  }
  .card-title {
    font-size: 1.077rem;
    border-bottom: solid 2px $color-work;
    color: $color-work;
    line-height: 1;
    margin: 0 0 5px 0;
    padding-bottom: 5px;
  }
  .card-title_s {
    font-size: .85rem;
    border-left: solid 4px $color-work;
    line-height: 1.2;
    margin: 10px 0 5px 0;
    padding: 4px 0 4px 4px;
  }
  .card-text {
    font-size: .85rem;
    margin: 0;
  }
  .card-image {
    text-align: center;
    img {
      padding:5px;
    }
  }
  .card-link {
    text-align: center;
  }
  .work-left {
  }
  .work-right {
    margin-top: 10px;
    text-align: center;
  }
  @media (min-width: $screen-md-min) {
    padding:16px;
    margin-bottom: $gutter;
    .card-title {
      font-size: 1.7rem;
      margin: 0 0 15px 0;
      padding-bottom: 10px;
    }
    .card-title_s {
      font-size: 1rem;
    }
    .card-text {
      font-size: 1rem;
    }
    .card-link {
      text-align: right;
    }
    .work-left {
      float:left;
      width: 55%;
    }
    .work-right {
      text-align: center;
      float:right;
      width:45%;
    }
  }
}

//
// 会社概要
//
.side-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  .menu-item{
    display: block;
    margin-bottom: 10px;
    max-width: 220px;
    a {
      font-size: 1.38rem;
      font-weight: bold;
      display: block;
      border: solid 3px #c7c7c7;
      color: $color-company;
      text-align: center;
      text-decoration: none;
      height: 60px;
      line-height: 60px;
      &:hover {
        background-color: #fff8f4;
      }
    }
  }
}
.company-article {
  .company-h2 {
    position: relative;
      font-size: 1.08rem;
      font-weight: bold;
      display: block;
      border: solid 3px #c7c7c7;
      color: $color-company;
      text-align: center;
      text-decoration: none;
      height: 53px;
      line-height: 53px;
      cursor: pointer;
      &:hover {
        background-color: #fff8f4;
      }
    &:before {
      content: " ";
      display: block;
      position: absolute;
      background-image: url(../img/common/icon_plus.svg);
      width:20px;
      height: 20px;
      right: 12px;
      top:14px;
    }
    &.open:before {
      background-image: url(../img/common/icon_minus.svg);
    }
    @media (min-width: $screen-md-min) {
      color: $color-company;
      font-size:1.7rem;
      border: 0;
      text-align: left;
      height: auto;
      line-height: inherit;
      border-bottom: solid 2px $color-company;
      margin: 0 0 20px 0;
      cursor: auto;
      &:hover {
        background-color: transparent;
      }
      &:before {
        display: none;
      }
    }
  }
  .company-p {
    margin: 0;
    font-size:0.85rem;
    @media (min-width: $screen-md-min) {
      font-size:1rem;
    }
  }
  .company-philosophy {
    font-family: $font-family-serif;
    font-size: 1.6rem;
    @media (min-width: $screen-md-min) {
      font-size: 1.8rem;
    }
  }
  
  .president {
    font-size: 1.15rem;
    font-weight: bold;
  }  
}
.company-contents {
  display: none;
  padding: 0 1em;
  &.open {
    display: block;
  }
  @media (min-width: $screen-md-min) {
    padding: 0;
    display: block;
  }
}
  .tbl-about {
    display: block;

    input {
      max-width: 100%;
    }

    tbody {
      display: block;
    }

    tr {
      display: block;
    }

    th,
    td {
      display: block;
      padding: .4em .5em;
      font-size: .85rem;
    }

    th {
      background-color: #eee;
      font-weight: bold;
      letter-spacing: .25em;
      text-align: left;
    }

    @media (min-width: $screen-md-min) {
      border-bottom: 1px solid #aeaeae;
      border-collapse: collapse;
      border-left: 0;
      border-spacing: 0;
      display: table;
      margin-bottom: 1em;
      width: 100%;

      tbody {
        display: table-row-group;
      }

      tr {
        display: table-row;
      }

      caption {
        text-align: left;
      }

      th,
      td {
        border-top: 1px solid #aeaeae;
        display: table-cell;
        padding: 10px;
        font-size: 1rem;
      }

      th {
        background-color: transparent;
        font-weight: normal;
        letter-spacing: 0;
        text-indent: 0;
        width: 155px;
        vertical-align: top;
      }

      td {
        vertical-align: top;
      }

    }
  }

  .tbl-history {
    display: block;

    input {
      max-width: 100%;
    }

    tbody {
      display: block;
    }

    tr {
      display: block;
    }

    th,
    td {
      display: block;
      padding: .4em .5em;
      font-size: .85rem;
    }

    th {
      background-color: #eee;
      font-weight: bold;
      letter-spacing: .25em;
      text-align: left;
    }

    @media (min-width: $screen-md-min) {
      border-bottom: 1px solid #aeaeae;
      border-collapse: collapse;
      border-left: 0;
      border-spacing: 0;
      display: table;
      margin-bottom: 1em;
      width: 100%;

      tbody {
        display: table-row-group;
      }

      tr {
        display: table-row;
      }
      tr:nth-child(odd) {
        background: #f2f2f2;
      }
      
      caption {
        text-align: left;
      }

      th,
      td {
        border-top: 1px solid #aeaeae;
        display: table-cell;
        padding: 10px;
        font-size: 1rem;
      }

      th {
        background-color: transparent;
        font-weight: normal;
        letter-spacing: 0;
        text-indent: 0;
        width: 155px;
        vertical-align: top;
      }

      td {
        vertical-align: top;
      }

    }
  }


