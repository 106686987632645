@charset "UTF-8";

input[type="button"],
input[type="submit"],
.btn-main,
.btn-sub,
.btn-accent,
.btn-dark,
.btn {
  background-color: $color-btn-bg;
  border: solid 1px $color-btn-border;
  box-shadow: 1px 1px 1px 0 lighten($color-text, 60%);
  color: $color-text;
  display: inline-block;
  line-height: 1;
  padding: 1em 3em;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: $color-btn-hover;
    color: $color-text;
  }
}

.btn-main {
  background-color: $color-main;
  border: solid 1px $color-main;
  color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-main;
  }
}

.btn-sub {
  background-color: $color-sub;
  border: solid 1px $color-sub;
  color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-sub;
  }
}

.btn-accent {
  background-color: $color-accent;
  border: solid 1px $color-accent;
  color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-accent;
  }
}

.btn-dark {
  background-color: $color-dark-bg;
  border: solid 1px $color-dark-border;
  color: $color-white;

  &:hover {
    background-color: $color-white;
    color: $color-dark-bg;
  }
}
