@charset "UTF-8";
// COLOR SETTING
$color-main: #00f !default;
$color-sub: #ff0 !default;
$color-accent: #f00 !default;
$color-text:  #000 !default;
$color-gray: #ccc !default;
$color-light: lighten($color-text, 60%) !default;
$color-superlight: lighten($color-main, 45%) !default;

.page-article {
// Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-feature-settings: 'palt';
    font-weight: normal;
    line-height: 1;
    margin: 0;
    padding: 0;

    small {
      font-size: 70%;
    }
  }

  h1,
  h2,
  h3 {
    margin-bottom: .5em;
    margin-top: .5em;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.5em;
  }

  h3 {
    font-size: 2em;
  }

  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    margin-top: 1em;
  }

  h4 {
    font-size: 1.5em;
  }

  h5 {
    font-size: 1em;
  }

  h6 {
    font-size: 80%;
  }

  // Body text
  p {
    font-feature-settings: 'pkna';
    line-height: $line-height-base;
    margin-bottom: 1em;
//    text-align: justify;
  }
  // Marker Unde Line
  mark {
    background: linear-gradient(transparent 60%, $color-mark 60%);
    padding: 0;
  }
  // Code
  code {
    background-color: $color-gray;
    padding: .1em .2em;
  }
  // Lists
  ul,
  ol {
    margin-bottom: 1em;

    ul,
    ol {
      margin-bottom: 0;
    }
  }
  // Description Lists
  dl {
    margin: 0 0 1em;
  }

  dt,
  dd {
    line-height: $line-height-base;
  }

  dt {
    border-bottom: solid 2px $color-sub;
    font-weight: bold;
    letter-spacing: .1em;
    padding: .2em .5em;
  }

  dd {
    margin-bottom: 1em;
    margin-left: 0; // Undo browser default
    padding: .5em;
  }
  // Blockquotes
  blockquote {
    border-left: 5px solid $color-sub;
    margin: 0 0 1em;
    padding: .5em;

    p:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: 0;
    }

    footer {
      font-size: 80%;
    }
  }
  // pre
  pre {
    background-color: $color-light-bg;
    border: 1px solid $color-light-border;
    font-family: Consolas, Monaco, Lucida Console, monospace;
    margin-bottom: 1em;
    overflow: auto;
    padding: .5em;

    code {
      background-color: $color-dark-bg;
      color: $color-white;
      display: block;
      margin: -.5em;
      padding: .5em;
    }
  }

  // table
  table {
    border-bottom: 1px solid $color-light-border;
    border-collapse: collapse;
    border-right: 1px solid $color-light-border;
    border-spacing: 0;
    margin-bottom: 1em;
    width: 100%;

    caption {
      text-align: left;
    }

    th,
    td {
      border-left: 1px solid $color-light;
      border-top: 1px solid $color-light;
      padding: .1em .5em;
    }

    th {
      background-color: $color-light-bg;
      font-weight: normal;
      text-align: left;
    }

    td {
      vertical-align: top;
    }
  }

  // レスポンシブ対応
  .tbl-responsive {
    display: block;

    input {
      max-width: 100%;
    }

    tbody {
      display: block;
    }

    tr {
      display: block;
    }

    th,
    td {
      display: block;
      padding: .1em .5em;
    }

    th {
      background-color: $color-light-bg;
      font-weight: normal;
      letter-spacing: .25em;
      text-align: left;
    }

    @media (min-width: $screen-md-min) {
      border-bottom: 1px solid $color-light;
      border-collapse: collapse;
      border-left: 0;
      border-right: 1px solid $color-light;
      border-spacing: 0;
      display: table;
      margin-bottom: 1em;
      width: 100%;

      tbody {
        display: table-row-group;
      }

      tr {
        display: table-row;
      }

      caption {
        text-align: left;
      }

      th,
      td {
        border-left: 1px solid $color-light;
        border-top: 1px solid $color-light;
        display: table-cell;
        padding: .1em .5em;
      }

      th {
        background-color: $color-light-bg;
        font-weight: normal;
        letter-spacing: 0;
        text-indent: 0;
      }

      td {
        vertical-align: top;
      }

    }
  }
}
