@charset "utf-8";

// Clearfix
.clearfix {
  @include clearfix();
}

// Quick floats
.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.pull-center {
  // must width settings
  display: block;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

// image tool
// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
  backface-visibility: hidden;
  height: auto;
  max-width: 100%;
}

// line bottom used em.
@for $i from 0 through 5 {
  .br0#{$i} {
    margin-bottom: #{$i}em !important;
  }
}

// margin & padding
@for $i from 0 through 20 {
  @if $i *5 < 10{
    .mt0#{$i *5} {
      margin-top: #{$i *5}px;
    }

    .mr0#{$i *5} {
      margin-right: #{$i *5}px;
    }

    .mb0#{$i *5} {
      margin-bottom: #{$i *5}px;
    }

    .ml0#{$i *5} {
      margin-left: #{$i *5}px;
    }

    .pt0#{$i *5} {
      padding-top: #{$i *5}px;
    }

    .pr0#{$i *5} {
      padding-right: #{$i *5}px;
    }

    .pb0#{$i *5} {
      padding-bottom: #{$i *5}px;
    }

    .pl0#{$i *5} {
      padding-left: #{$i *5}px;
    }
  }@else{
    .mt#{$i *5} {
      margin-top: #{$i *5}px;
    }

    .mr#{$i *5} {
      margin-right: #{$i *5}px;
    }

    .mb#{$i *5} {
      margin-bottom: #{$i *5}px;
    }

    .ml#{$i *5} {
      margin-left: #{$i *5}px;
    }

    .pt#{$i *5} {
      padding-top: #{$i *5}px;
    }

    .pr#{$i *5} {
      padding-right: #{$i *5}px;
    }

    .pb#{$i *5} {
      padding-bottom: #{$i *5}px;
    }

    .pl#{$i *5} {
      padding-left: #{$i *5}px;
    }
  }
}
