@charset "UTF-8";

.header-nav {
  @include clearfix();
  padding: 0;
  .site-logo {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    height: 58px;
  }
  @media (min-width: $screen-md-min) {
    .site-logo {
      height: 65px;
      float: left;
    }
  }
}

.main-menu {
  
  .menu {
    @include clearfix();
    margin: 0;
    padding: 0;
  }
  position: absolute;
  right: 12px;
  top:0px;
  width: auto;

  .menu-item {
    border-bottom: 0;
    display: block;
    float: left;
    margin: 0 2px;
    width:55px;
    height: 50px;
    border: solid 1px #808080;
    border-radius: 2px;
    a {
      display: block;
      text-align: center;
      color: $color-text;
      font-size: .6rem;
      .icon {
        display: block;
        text-align: center;
        width: 26px;
        margin: 0 auto;
        margin-bottom: 3px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      line-height: 1;
      padding: .25em 0;
      text-decoration: none;
    }
    
    &.menu-home:hover,
    &.menu-home.current {
      border-bottom: solid 3px $color-home;
      a {
        color:$color-home;
      }
    }
    &.menu-work:hover,
    &.menu-work.current {
      border-bottom: solid 3px $color-work;
      a {
        color:$color-work;
      }
    }
    &.menu-company:hover,
    &.menu-company.current {
      border-bottom: solid 3px $color-company;
      a {
        color:$color-company;
      }
    }

  }
  @media (min-width: $screen-md-min) {
    top:-15px;
    .menu-item {
      border-bottom: 0;
      display: block;
      float: left;
      width:auto;
      height: auto;
      margin: 0 1em;
      border: 0;
    }
    .menu-item a {
      font-size: 1.08em;
      .icon {
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
}


.menu-btn {
  z-index: 1000;
  position: fixed;
  top:5px;
  right:5px;
  width:48px;
  height:48px;
  padding-top:8px;
  font-size:10px;
  border-radius: 50%;
  line-height: 1;
  text-align: center;
  background-color:$color-main;
  color:#fff;
  cursor: pointer;
  transition: all 0.4s;
  box-shadow: 0 1px 2px #666;
  &:before {
    display: block;
    font-size:20px;
    margin-bottom: 2px;
    font-family: 'FontAwesome';
    content:"\f0c9";
  }
  &:active {
    box-shadow: 0 0 0 #666;
    top:7px;
  }
  &.open {
    &:before {
      content:"\f00d";
    }
  }
  @media (min-width: $screen-md-min) {
    display:none;
  }
}