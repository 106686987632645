@charset "UTF-8";

body {
  font-size: $font-size-base;
//  font-size: 12px;
  padding-bottom: 139px;
  @media (min-width: $screen-md-min) { 
    padding-bottom: 0;
  }
}

//
// ヘッダー
//
.l-header {
  background-color: $color-white;
//  background-color: #ccc;
  @media (min-width: $screen-md-min) {
    border-top: solid 7px $color-main;
  }
}
//
// コンテンツ
//
.l-main {
  
}
//
// フッター
//
.l-footer {
  background-color: $color-main;
}
// ページトップに戻るボタン
.btn-pagetop {
  bottom: 15px;
  color: $color-text;
  cursor: pointer;
  display: none;
  height: 44px;
  line-height: 1;
  opacity: 0.8;
  position: fixed;
  right: 15px;
  text-align: center;
  transform: perspective(0);
  transition: transform .3s ease-in-out,background-color .3s ease-in-out;
  width: 44px;
  z-index: 1000;

  &:before {
    content: ' ';
    display: block;
    width:44px;
    height: 44px;
    background-image: url(../img/common/icon_top.svg);
  }

  &.visible {
    animation-duration: .5s;
    animation-name: fade-in;
    display: block;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.2);
  }
  @media (min-width: $screen-md-min) {
    display: block;
  }
}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

//
// base
//
// header
.header-text {
  background-color: $color-main;
  color: $color-white;
  font-size:0.77em;
  line-height: 1.2;
  margin: 0 0 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-md-min) {
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
    margin: 15px 0 10px 0;
    background-color: transparent;
    color: $color-text;
    width: $container-md;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

// privacypolicy
.btn-privacy {
  font-size: 0.9rem;
  display: block;
  background-color: #fff;
  text-decoration: none;
  width:280px;
  margin: 10px auto;
  padding: 8px 0;
  &:after {
    content: '\f08e';
    font-family: 'FontAwesome';
    margin-left: .5em;
  }
}
.privacy-link {
  display: none;
  @media (min-width: $screen-md-min) {
    display: inline-block
  }
  color:$color-text;
  text-decoration: none;
  &:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    margin-right: .5em;
  }
}
.hidden {
  display: none;
}
.box-privacy {
  background-color: #fff;
  padding: 1em;
  @media (min-width: $screen-md-min) {
    padding: 3em;
  }
}
.box-contact {
  padding: 0 .5em;
  border: solid 1px #ccc;
}

//
// map
//
#map04,
#map03,
#map02,
#map01 {
  margin-top: 10px;
  height: 300px;
  width: 100%;
  background-color: #ccc;
}

// footer
.copyright {
  font-size: .76rem;
  color:$color-white;
  text-align: center;
  line-height: 1;
  margin: 10px 0;
  @media (min-width: $screen-md-min) {
    font-size: 1rem;
  }
}

.googlemaps {
  position: relative;
  height: 300px;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
// adjust
img {
  max-width: 100%;
  height: auto;
}
.sp-show {
  display: inherit;
}
.sp-none {
  display: none;
}
@media (min-width: $screen-md-min) {
  .sp-show {
    display: none;
  }
  .sp-none {
    display: inherit;
  } 
}