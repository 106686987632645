@charset "UTF-8";

.sec-slide {
  background-color: red;
}
//
// top slide
//
.top-slide_section {
  position: relative;
}
.top-slide_box {
  margin: 0 auto;
}
.top-slide {
  width:100%;
  
  img {
    margin: 0 auto;
  }
}
// スライドショー用ボタン
.btn_slide {
  position: relative;
  margin-top: 20px;
  display: inline-block;
//  border: solid 1px #fff;
  color: #fff;
  background-color: rgba(0, 56, 130, 0.75);
  font-size:12px;
  text-decoration: none;
  padding:.5em 1em;
  width:135px;
  &:after {
      position: absolute;
      right:10px;
      font-family: 'FontAwesome';
      content:"\f105";
      margin: 0 0.1em;
  }
  &:hover {
    background-color: rgba(0, 56, 130, 1);
    color: #fff;
  }
}

.sec-lineup {
  
}
.lineup-title_home,
.lineup-title_work {
  font-size: 1.15rem;
  color:$color-home;
  border-bottom: solid 2px $color-home;
  text-align: center;
  line-height: 1;
  padding: 5px 0;
  margin-bottom: 12px;
  @media (min-width: $screen-md-min) {
    font-size: 1.38rem; 
  }
}
.lineup-title_work {
  color:$color-work;
  border-bottom: solid 2px $color-work;
}
.row-inner {
  @include clearfix();
  margin-left: -5px;
  margin-right: -5px;
  @media (min-width: $screen-md-min) {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
  }
}

.col-lineup-6 {
  float:left;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
  @media (min-width: $screen-md-min) {
    padding-left: 12px;
    padding-right: 12px;
  }
}



.card-lineup_home {
  display: block;
  border: solid 1px $color-home;
  margin-bottom: 10px;
  text-decoration: none;
  padding:5px 7px;
  &:hover {
    background-color: #fafaff;
  }
  .card-title {
    font-size:.92rem;
    display: block;
    text-align: center;
    background-color: $color-home;
    color:$color-white;
    margin-bottom: 7px;
  }
  .card-image {
    display: block;
    text-align: center;
    img {
      vertical-align: bottom;
    }
  }
  @media (min-width: $screen-md-min) {
    margin-bottom: $gutter;
    padding:14px 24px;
    .card-title {
      font-size:1.15rem;
      margin-bottom: 10px;
    }
  }
}
.card-lineup_work {
  display: block;
  border: solid 1px $color-work;
  margin-bottom: 10px;
  text-decoration: none;
  padding:5px 7px;
  &:hover {
    background-color: #fafaff;
  }
  .card-title {
    font-size:.92rem;
    display: block;
    text-align: center;
    background-color: $color-work;
    color:$color-white;
    margin-bottom: 7px;
  }
  .card-image {
    display: block;
    text-align: center;
    img {
      vertical-align: bottom;
    }
  }
  @media (min-width: $screen-md-min) {
    margin-bottom: $gutter;
    padding:14px 24px;
    .card-title {
      font-size:1.15rem;
      margin-bottom: 10px;
    }
  }
}

